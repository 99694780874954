<template>
  <v-select v-model="filter.sortedBy" :disabled="isSearching" :items="items"
            :label="$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.sortBy')" :loading="isSearching"
            hide-details outlined/>
</template>

<script>
import { SET_FILTER } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccSortDropdown',
  data () {
    return {
      filter: null,
      items: null
    }
  },
  computed: {
    isSearching () {
      return this.$store.state.search.isSearching
    }
  },
  watch: {
    filter: {
      handler: function () {
        this.$store.commit(SET_FILTER, this.$lodash.cloneDeep(this.filter))
      },
      deep: true
    }
  },
  methods: {
    cloneFilter () {
      this.filter = this.$lodash.cloneDeep(this.$store.state.filter)
    }
  },
  created () {
    this.cloneFilter()
    this.items = [
      {
        header: this.$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.sortByName')
      },
      {
        text: 'A-Z',
        value: 'desc'
      },
      {
        text: 'Z-A',
        value: 'asc'
      },
      {
        divider: true
      },
      {
        header: this.$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.sortByRates')
      },
      {
        text: this.$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.highToLow')
      },
      {
        text: this.$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.lowToHigh')
      }
    ]
  }
}
</script>
